import NextLink from 'next/link';
import { Box, Button } from '@mui/material';

import { getHomeUrl } from 'src/utils/is-allowed-workspace';
import { useAuth } from 'src/hooks/use-auth';

const AuthFailButtons = () => {
  const { logout } = useAuth();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Button
          variant="outlined"
          onClick={async () => {
            history.back(); //Go to the previous page
          }}
        >
          Back
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 6,
        }}
      >
        <Button
          variant="outlined"
          onClick={async () => {
            await logout();
            window.location = '/authentication/login';
          }}
        >
          Logout and back to login page
        </Button>
      </Box>
    </>
  );
};

export default AuthFailButtons;
